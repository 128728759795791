<template>
  <!-- 设备固件添加页面 -->
  <moe-page title="设备固件上传">
    <moe-form ref="firmwareForm" :model="firmware" :rules="firmwareRules">
      <el-form-item label="文件" prop="softwarePath">
        <moe-upload
          v-model="firmware.softwarePath"
          action="/file/uploadSoftware"
          name="multipartFile"
          :limit="1"
          :size="100"
          :accept="'.' + if_firmware.type"
          :suffix="[if_firmware.type]"
          upload-text="上传文件"
          @change="$refs['firmwareForm'].validateField('softwarePath')">
          <div slot="tip" style="color: red" v-if="if_firmware.is_tips">
            注：请将固件文件与版本文件放入文件夹中并打包成一个zip文件上传,版本文件固定名称:version.txt，可点击下方按钮下载模板
          </div>
        </moe-upload>

        <el-button
          v-if="if_firmware.is_tips"
          type="text"
          icon="el-icon-download"
          size="mini"
          @click="downloadTemplate">
          下载模板
        </el-button>
      </el-form-item>
      <template slot="tool">
        <el-button
          type="primary"
          icon="el-icon-upload2"
          :loading="firmwareLoad"
          @click="firmwareInfoSubmit">
          {{ firmwareLoad ? '提交中' : '提交信息' }}
        </el-button>
      </template>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'firmwareAdd',
  data() {
    //设备固件信息
    const firmware = {
      typeId: '', //设备类型
      softwarePath: '', //文件地址
    };

    //设备固件数据校验
    const verify = this.$moe_verify.verifyForm;
    const firmwareRules = {
      softwarePath: verify.isEmpty('请上传文件'),
    };

    const routerParams = this.$route.query;

    return {
      firmware, //设备固件信息
      firmwareRules, //设备固件数据校验
      firmwareLoad: false, //提交状态

      // 页面参数
      routerParams
    };
  },
  created() {
    this.firmware.typeId = this.$route.query.id;
  },
  computed:{
    // 判断 wifi固件和蓝牙固件
    if_firmware(){
      let wireless = this.routerParams.wireless;
      // 是否为蓝牙固件
      let fir_type = wireless === '1';
      let type = fir_type ? 'bin' : 'zip';
      // 蓝牙隐藏固件
      let is_tips = !fir_type;
      let returnO = {
        type,
        is_tips
      }

      return returnO;
    },

  },
  methods: {
    /**
     * 提交设备固件信息
     **/
    firmwareInfoSubmit() {
      // 表单校验
      this.$refs['firmwareForm'].validate(() => {
        this.firmwareLoad = true;
        this.$moe_api.EQUIPMENT_API.sfirmwareUpload(this.firmware).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('固件上传成功');
            this.$router.go(-1);
          } else {
            this.$moe_msg.error(data.message);
          }
        }).finally(() => {
          this.firmwareLoad = false;
        });
      });
    },

    /* 下载固件模板 */
    downloadTemplate() {
      // let fir = this.if_firmware;
      // window.open(
      //   this.$moe_config.fileUrl + '/petsFile/test/DU3L_TEST.'+ fir.type,
      //   '_black'
      // );
      window.open(
        this.$moe_config.oss_imgUrl + '/device/model/software/DU3L_TEST.zip',
        '_black'
      );
    },
  },
};
</script>
